<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center"
        style="color: #2b7dec"
        @click="navBack"
      >
        <v-icon color="#2B7DEC"> mdi-chevron-left </v-icon>
        Kembali
      </v-col>
    </v-row>
    <v-row class="mt-n5 mb-4">
      <v-col class="txt26-black" cols="12"> Tambah Perusahaan </v-col>
    </v-row>
    <v-card class="px-4 pb-3">
      <tambah-perusahaan :register="false" />
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import TambahPerusahaan from "../registrasi/profesional/RegistrasiProfesionalPerusahaan.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    TambahPerusahaan,
  },

  data: () => ({}),

  computed: {},

  watch: {},

  created() {},

  methods: {
    navBack() {
      this.$router.push({ name: "PengaturanPerusahaan" });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt20-black {
  @extend .h-5;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
